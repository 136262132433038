export default ($axios) => ({
  // 登入后显示超提
  getOverDelivery() {
    return $axios.$get('/businesscenter/delivery/overDelivery')
  },
  // 公司信息不全
  getByCorpIdFileTemp() {
    return $axios.$get('/businesscenter/corpAuth/getByCorpIdFileTemp')
  },
  // 消息弹框
  getDialogList() {
    return $axios.$get('/businesscenter/tecwindow/isWindow')
  },
  // 登入后已经完成成品入库预约提货
  getc2mDelivery() {
    return $axios.$get('/businesscenter/buyercprocessorder/getAppointmentWarn')
  },
  // 加工厂续签提示
  getFactory() {
    return $axios.$get('/businesscenter/factorySnapshot/popUpPrompt')
  }
})
