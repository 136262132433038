import { render, staticRenderFns } from "./homeDialog.vue?vue&type=template&id=975ea992"
import script from "./homeDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./homeDialog.vue?vue&type=script&setup=true&lang=js"
import style0 from "./homeDialog.vue?vue&type=style&index=0&id=975ea992&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports