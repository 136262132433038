import { state } from './app'

const getters = {
  token: (state) => state.auth.token,
  userInfo: (state) => state.app.userInfo,
  corpId: (state) => state.app.corpId,
  corps: (state) => state.app.corps,
  permissions: (state) => state.app.permissions,
  routes: (state) => state.app.routes,
  buttons: (state) => state.app.buttons,
  title: (state) => state.app.title,
  dropMenus: (state) => state.app.dropMenus,
  fixedMenus: (state) => state.app.fixedMenus,
  transactionParams: (state) => state.app.transactionParams,
  homeTreeData: (state) => state.app.homeTreeData,
  corpChangeFlag: (state) => state.app.corpChangeFlag,
  highlightPath: (state) => state.app.highlightPath,
  loginCorpAuth: (state) => state.app.loginCorpAuth,
  // home
  topBanners: (state) => state.home.topBanners,
  mainBanners: (state) => state.home.mainBanners,
  waistBanners: (state) => state.home.waistBanners,
  infoBanners: (state) => state.home.infoBanners,
  c2mBanners: (state) => state.home.c2mBanners,
  msgCount: (state) => state.home.msgCount,
  currentTime: (state) => state.home.currentTime,

  // shopMall
  condition: (state) => state.spotMall.condition,
  varieties: (state) => state.spotMall.varieties,
  categories: (state) => state.spotMall.categories,
  material: (state) => state.spotMall.material,
  oiginDescs: (state) => state.spotMall.oiginDescs,
  repoDtos: (state) => state.spotMall.repoDtos,
  deliPlaces: (state) => state.spotMall.deliPlaces,
  initMaterial: (state) => state.spotMall.initMaterial,
  initOiginDescs: (state) => state.spotMall.initOiginDescs,
  initRepoDtos: (state) => state.spotMall.initRepoDtos,
  initDeliPlaces: (state) => state.spotMall.initDeliPlaces,
  sortDeliPlaces: (state) => state.spotMall.sortDeliPlaces,
  measure: (state) => state.spotMall.measure,
  qualityInfo: (state) => state.spotMall.qualityInfo,
  sizeInfo: (state) => state.spotMall.sizeInfo,
  specialPrice: (state) => state.spotMall.specialPrice,
  source: (state) => state.spotMall.source,
  types: (state) => state.spotMall.types,
  openTime: (state) => state.spotMall.openTime,
  closeTime: (state) => state.spotMall.closeTime,
  nowTime: (state) => state.spotMall.nowTime,
  purpose: (state) => state.spotMall.purpose,
  // loginDialog
  dialogVisible: (state) => state.loginDialog.dialogVisible,
  // tipMsgDialog
  tipMsgDialogVisible: (state) => state.tipMsgDialog.tipMsgDialogVisible,
  tipMsgState: (state) => state.tipMsgDialog.tipMsgState,
  tipMessage: (state) => state.tipMsgDialog.tipMessage,
  cartsNumber: (state) => state.spotMall.cartsNumber,
  corpIdFile: (state) => state.tipMsgDialog.corpIdFile,
  overDelivery: (state) => state.tipMsgDialog.overDelivery,
  homeTipList: (state) => state.tipMsgDialog.homeTipList,
  c2mDelivery: (state) => state.tipMsgDialog.c2mDelivery,
  factory: (state) => state.tipMsgDialog.factory
}
export default getters
